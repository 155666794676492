import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AiOutlineSafetyCertificate,
  AiOutlineSafety,
  AiOutlineWhatsApp,
} from "react-icons/ai";
const Benef = () => {
  const navigate = useNavigate();
  return (
    <div>
      <section className="mx-auto container bg-white pt-16">
        <div className="px-4 lg:px-0">
          <div role="contentinfo" className="flex items-center flex-col px-4">
            <p
              tabIndex={0}
              className="focus:outline-none uppercase text-sm text-center text-gray-500 leading-none"
            >
              Contamos con
            </p>
            <h1
              tabIndex={0}
              className="focus:outline-none text-4xl lg:text-4xl pt-4 font-extrabold text-center leading-tight text-gray-800 lg:w-7/12 md:w-9/12 xl:w-5/12"
            >
              MÁS BENEFICIOS
            </h1>
          </div>
        </div>
      </section>
      <p>
        <div className="2xl:container 2xl:mx-auto lg:py-0 lg:px-20 md:py-12 md:px-6 py-9 px-4 ">
          <div className="flex  justify-center items-center w-full">
            <div className="w-full sm:w-96 md:w-8/12 lg:w-full grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-28 sm:gap-x-6 sm:gap-y-12 gap-y-12 sm:mt-14 mt-10">
              <div>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-5xl text-[#1e49a6]"
                >
                  <AiOutlineSafetyCertificate />
                </svg>
                <p className="font-semibold text-xl leading-5 text-[#1e49a6] lg:mt-10 mt-9">
                  ATENCIÓN PRE HOSPITALARIA
                </p>

                <ol class="list-decimal mt-4 text-base leading-6 text-normal px-5">
                  <li>
                    Telemedicina: consulta inicial multicanal (medica,
                    nutricional y emocional) atención 24/7.
                  </li>
                  <li>Check up básico anual, sin costo.</li>
                  <li>
                    Servicio odontológico: 2 consultas anuales sin costo,
                    consulta y eliminación de sarro y descuentos en más de 100
                    tratamientos.
                  </li>
                  <li>Ambulancia terrestre.</li>
                  <li>
                    Médico a domicilio con una tarifa especial de $450 (de 7:00
                    a 20:00 hrs.).
                  </li>
                  <li>
                    Costos preferentes de Médicos Especialistas. Red de más de
                    8,000 médicos a nivel nacional, con una tarifa entre $300 y
                    $600 máximo por consulta.
                  </li>
                  <li>
                    Red de descuentos: en medicinas, laboratorios (hasta el 50%
                    en los mejores laboratorios clínicos de México),
                    imagenología, enfermería, rehabilitación, equipo ortopédico.
                  </li>
                  <li>
                    Salud visual con descuentos del 20% en ópticas Devlin.
                  </li>
                  <li>Vacunas para menores de 5 años.</li>
                  <li>
                    Detección oportuna de cáncer: ginecológico, próstata, colon
                    y recto.
                  </li>
                  <li>
                    Campañas de detección de Hipertensión arterial, de
                    dislipidemias y diabetes melitus.
                  </li>
                  <li>Check up preventivo.</li>
                </ol>
              </div>
              <div>
                <svg
                  width="50"
                  height="50"
                  viewBox="0 0 50 50"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-5xl text-[#1e49a6]"
                >
                  <AiOutlineSafety />
                </svg>
                <p className="font-semibold text-xl leading-5 text-[#1e49a6] lg:mt-10 mt-9">
                  ATENCIÓN HOSPITALARIA
                </p>
                <ol class="list-decimal mt-4 text-base leading-6 text-normal px-5">
                  <li>
                    Sin deducible, ni coaseguro, reportando todos tus eventos
                    previamente.
                  </li>
                  <li>Pago de tu cuenta hospitalaria al 100%</li>
                  <li>
                    Cobertura de Maternidad (Periodo de espera de 30 días) :
                    control prenatal, parto natural (Suma asegurada de 20 UMAM
                    $66,010.60 *) o cesárea (Suma asegurada de 25 UMAM
                    $82,513.25*), complicaciones del embarazo, cuidados del
                    recién nacido (Suma asegurada de $ 1 millón de pesos),
                    congénitos del recién nacido.
                  </li>
                  <p className=" font-bold mt-4 mb-4">
                    *La UMAM es la UMA Unidad de Medida y Actualización, es una
                    referencia económica y no es una moneda real, se usa para
                    fines legales y administrativos en Mexico.
                  </p>
                  <p className=" font-bold mt-4 mb-4">
                    Se calcula en base a su valor mensual , que para 2024 es de
                    $3,300.53.
                  </p>
                  <p className=" font-bold mt-4 mb-4">
                    Las Complicaciones en el embarazo y cuidados del recién
                    nacido es con una suma asegurada de $ 1 millón de pesos.
                  </p>
                  <li>
                    Te ofrecemos 2 opciones de suma asegurada, para cubrir la
                    cuenta del hospital y honorarios médicos hospitalarios, en
                    URGENCIAS y ENFERMEDADES. Cuando tu diagnóstico médico lo
                    requiera.
                  </li>

                  <li>
                    Tu suma asegurada se renueva anualmente, en la renovación de
                    tu membresía.
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </p>
      <div className="overflow-y-hidden">
        <div className="pb-16" style={{ fontFamily: '"Lato", sans-serif' }}>
          {/* Code block starts */}
          <dh-component>
            <section className="bg-[#1e49a6] py-12 mt-12">
              <div
                tabIndex={0}
                aria-label="group of cards"
                className="focus:outline-none px-4 lg:px-0"
              >
                <div className="mx-auto container flex flex-wrap px-2 lg:px-24">
                  <div
                    tabIndex={0}
                    aria-label="card 1"
                    className="focus:outline-none flex sm:w-full md:w-1/2 lg:border-r md:border-r sm:border-r-0 border-indigo-400 pb-10 lg:pt-10"
                  >
                    <div className=" flex flex-shrink-0 mr-5 text-white">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg2.svg"
                        alt="drawer"
                      />
                    </div>
                    <div className="md:w-9/12 lg:w-9/12">
                      <h2
                        tabIndex={0}
                        className="focus:outline-none text-lg font-semibold leading-5 text-white"
                      >
                        Servicio integral funerario
                      </h2>
                      <p
                        tabIndex={0}
                        className="focus:outline-none text-base text-white leading-normal xl:w-10/12 pt-2"
                      >
                        Incluye cremación o inhumación, así como gestión de
                        trámites.
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={0}
                    aria-label="card 2"
                    className="focus:outline-none flex sm:w-full md:w-1/2 lg:pb-10 lg:pt-10"
                  >
                    <div className=" flex flex-shrink-0 sm:ml-0 md:ml-10 lg:ml-10 mr-5 text-white">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg2.svg"
                        alt="check"
                      />
                    </div>
                    <div className="md:w-9/12 lg:w-9/12 ">
                      <h2
                        tabIndex={0}
                        className="focus:outline-none text-lg font-semibold leading-5 text-white"
                      >
                        Seguro por muerte accidental
                      </h2>
                      <p
                        tabIndex={0}
                        className="focus:outline-none text-base text-white leading-normal xl:w-10/12 pt-2"
                      >
                        Con una suma asegurada de $100,000 pesos
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={0}
                    aria-label="card 3"
                    className="focus:outline-none flex sm:w-full md:w-1/2 lg:border-t md:border-t sm:border-t-0 lg:border-r md:border-r sm:border-r-0 border-indigo-400 pt-10 lg:pb-20"
                  >
                    <div className=" flex flex-shrink-0 mr-5 text-white">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg2.svg"
                        alt="html-tag"
                      />
                    </div>
                    <div className="md:w-9/12 lg:w-9/12 ">
                      <h2
                        tabIndex={0}
                        className="focus:outline-none text-lg font-semibold leading-5 text-white"
                      >
                        Asistencia
                      </h2>
                      <p
                        tabIndex={0}
                        className="focus:outline-none text-base text-white leading-normal xl:w-10/12 pt-2"
                      >
                        Asistencia en viajes internacionales en los 2 planes.
                      </p>
                    </div>
                  </div>
                  <div
                    tabIndex={0}
                    aria-label="card 4"
                    className="focus:outline-none flex sm:w-full md:w-1/2 lg:border-t md:border-t sm:border-t-0 border-indigo-400 pt-10 lg:pb-20"
                  >
                    <div className=" flex flex-shrink-0 sm:ml-0 md:ml-10 lg:ml-10 mr-5 text-white">
                      <img
                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/indigo_bg_with_divider-svg2.svg"
                        alt="display"
                      />
                    </div>
                    <div className="md:w-9/12 lg:w-9/12 ">
                      <h2
                        tabIndex={0}
                        className="focus:outline-none text-lg font-semibold leading-5 text-white"
                      >
                        Cobertura de emergencia en el extranjero
                      </h2>
                      <p
                        tabIndex={0}
                        className="focus:outline-none text-base text-white leading-normal xl:w-10/12 pt-2"
                      >
                        En el plan 2 con una suma asegurada hasta $50,000 USD
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </dh-component>
          {/* Code block ends */}
        </div>
        <div className=" text-center">
          <p className=" mt-4 text-center font-semibold">
            Horario de atención : lunes a viernes de 9 am a 6 pm sábado de 9 am
            a 4 pm
          </p>
          <a href="https://wa.me/525621624964?text=Vi%20en%20Smarts%20Finances%20su%20producto%20y%20me%20interesa">
            <button className=" bg-[#25D366] w-[280px] rounded-md font-medium my-5 mx-auto py-3 text-white">
              Dudas del producto
              <span className=" absolute  justify-center -mt-1 mr-3 px-2 text-white text-3xl ">
                <AiOutlineWhatsApp />
              </span>
            </button>
          </a>
        </div>
        <div className="flex relative items-center cursor-pointer pb-4 md:pb-4 pt-4">
          <button
            onClick={() => navigate("/mutuus")}
            className="bg-[#1e49a6] w-[300px] rounded-md font-medium my-6 mx-auto py-3 text-white"
          >
            {" "}
            Me interesa comprar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Benef;
