import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
const conservadora = [
  {
    fecha: "Sep-24",
    cmensual: 1.75,
    canualizada: 21,
    dmensual: 5,
    danualizada: 60,
    cetes: 10.44,
  },
  {
    fecha: "Ago-24",
    cmensual: 1.8,
    canualizada: 21.6,
    dmensual: 5,
    danualizada: 60,
    cetes: 10.65,
  },
  { fecha: "jul-24", cmensual: 1.75, canualizada: 21, cetes: 10.92 },
  { fecha: "jun-24", cmensual: 1.7, canualizada: 20.4, cetes: 10.97 },
  { fecha: "may-24", cmensual: 1.6, canualizada: 19.2, cetes: 10.99 },
  { fecha: "abr-24", cmensual: 1.8, canualizada: 21.6, cetes: 10.97 },
  { fecha: "mzo-24", cmensual: 1.7, canualizada: 20.4, cetes: 11.02 },
  { fecha: "feb-24", cmensual: 1.5, canualizada: 18, cetes: 11.05 },
  { fecha: "ene-24", censual: 0.5, canualizada: 6, cetes: 11.29 },
];

const Grafica = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(null);

  return (
    <>
      <div className='w-full sm:px-6'>
        <div className='px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg'>
          <div className='sm:flex items-center justify-between'>
            <p className='text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800'>
              ESTRATEGIAS SMART FINANCES 2024
            </p>
            <div>
              <button
                className='inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-[#92d468]  focus:outline-none rounded'
                onClick={() => navigate("/rendimientos2023")}
              >
                <p className='text-sm font-medium leading-none text-white'>
                  2023
                </p>
              </button>
            </div>
          </div>
        </div>
        <div className='bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto'>
          <table className='w-full whitespace-nowrap'>
            <thead>
              <tr className='h-16 w-full text-sm leading-none text-gray-800'>
                <th className='font-bold text-center pl-20'>Fecha</th>
                <th className='font-bold text-center pl-20 '>
                  Conservadora<td></td>Mensual
                </th>
                <th className='font-bold text-center pl-20'>
                  Conservadora<td></td>Anualizada
                </th>
                <th className='font-bold text-center pl-20 '>
                  Dinámica<td></td>Mensual
                </th>
                <th className='font-bold text-center pl-20'>
                  Dinámica<td></td>Anualizada
                </th>
                <th className='font-bold text-center pl-20'>
                  CETES 28 días <td></td>Promedio de la tasa mensual,
                  anualizada.<td></td> Fuente : Banco de Mexico
                </th>
              </tr>
              {conservadora.map((val, key) => {
                return (
                  <tr
                    key={key}
                    className='h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100'
                  >
                    <td className='pl-20 font-medium text-center'>
                      {val.fecha}
                    </td>
                    <td className='pl-20 font-medium text-center'>
                      {val.cmensual}%
                    </td>
                    <td className='pl-20 font-medium text-center'>
                      {val.canualizada}%
                    </td>
                    <td className='pl-20 font-medium text-center'>
                      {val.dmensual}%
                    </td>
                    <td className='pl-20 font-medium text-center'>
                      {val.danualizada}%
                    </td>
                    <td className='pl-20 font-medium text-center'>
                      {val.cetes}%
                    </td>
                  </tr>
                );
              })}
            </thead>
          </table>
        </div>
      </div>
    </>
  );
};

export default Grafica;
