import { useNavigate } from "react-router-dom";
import paypal from "../assets/logo/paypal.png";
import multib from "../assets/logo/multibank.png";
import mutt from "../assets/logo/mutuus.png";
import qual from "../assets/logo/logoquality.webp";
import log from "../assets/logogrande.png";
import generalsalud from "../assets/logo/generalsalud.png";
import stripe from "../assets/logo/stripe.png";
import {
  BsTelegram,
  BsWhatsapp,
  BsFacebook,
  BsInstagram,
} from "react-icons/bs";

export default function Index() {
  const navigate = useNavigate();

  // Function will execute on click of button
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("AVISO-DE-PRIVACIDAD.pdf").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "AVISO-DE-PRIVACIDAD.pdf";
        alink.click();
      });
    });
  };
  return (
    <div className>
      <div className="2xl:px-20 md:px-10 2xl:mx-auto 2xl:container">
        <div className="md:py-12 py-8 px-4">
          <div className="flex flex-col items-center justify-center">
            <h1 className="lg:text-5xl md:text-4xl text-2xl font-bold leading-10 text-gray-800">
              Nuestros socios de confianza
            </h1>
            <p className="text-base leading-normal text-center text-gray-600 mt-4 xl:w-1/2 w-10/12">
              Contamos con el respaldo de alianzas estrategicas para brindarte
              un mejor servicio.
            </p>
          </div>

          <div className="flex items-center justify-center mt-10">
            <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xl:gap-60 gap-6  ">
              <div className="md:w-48 w-full h-32 bg-gray-50 flex items-center justify-center">
                <a href="">
                  <img
                    width={200}
                    height={40}
                    viewBox="0 0 73 24"
                    fill="none"
                    src={multib}
                    alt="logo"
                    href="www.facebook.com"
                  />
                </a>
              </div>

              <div className="md:w-48 w-full h-32 bg-gray-50 flex items-center justify-center">
                <a href="">
                  <img
                    width={200}
                    height={24}
                    viewBox="0 0 73 24"
                    fill="none"
                    src={mutt}
                    alt="logo"
                  />
                </a>
              </div>

              <div className="md:w-48 w-full h-32 bg-gray-50 flex items-center justify-center">
                <a href="">
                  <img
                    width={180}
                    height={24}
                    viewBox="0 0 73 24"
                    fill="none"
                    src={generalsalud}
                    alt="logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className=" bg-linear-pink-invert pb-0">
        <div className="mx-auto container pt-20  flex flex-col items-center justify-center">
          <div className="cursor-pointer ">
            <img width={200} src={log} alt="logo" />
          </div>

          <div className="text-black flex flex-col md:items-center f-f-l pt-3">
            <h1 className="text-2xl font-bold">
              La decisión inteligente para tu vida
            </h1>

            <p>
              Correo:{" "}
              <a
                href="mailto:  contacto@smartsfinances.info"
                class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
              >
                contacto@smartsfinances.info
              </a>
            </p>
            <div className="pt-2  sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-6 gap-6 w-full flex items-center justify-center ">
              <a
                href="https://t.me/SmartsFinances"
                className="pt-5 text-2xl text-text-black cursor-pointer"
                target="_blank"
              >
                <BsTelegram />
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=6181386286"
                className="pt-5 text-2xl text-black cursor-pointer"
                target="_blank"
              >
                <BsWhatsapp />
              </a>
              <a
                href="https://www.facebook.com/SmartsFinances/"
                className="pt-5 text-2xl text-black cursor-pointer"
                target="_blank"
              >
                <BsFacebook />
              </a>
              <a
                href="https://www.instagram.com/sfsmartfinances/"
                className="pt-5 text-2xl text-black cursor-pointer"
                target="_blank"
              >
                <BsInstagram />
              </a>
            </div>

            <div className="my-6 text-base text-color f-f-l">
              <ul className="md:flex items-center">
                <button
                  className="md:mr-6 cursor-pointer pt-4 lg:py-0 "
                  onClick={() => navigate("/aboutus")}
                >
                  {" "}
                  Nosotros
                </button>

                <li
                  onClick={onButtonClick}
                  className="cursor-pointer pt-4 lg:py-0"
                >
                  Política de Privacidad
                </li>
              </ul>
            </div>
            <div className="text-sm text-color mb-10 f-f-l">
              <p className="">
                © 2023 The code House. by{" "}
                <a
                  className=" text-purple-600"
                  href="https://www.linkedin.com/in/jean-rodr%C3%ADguez-67234915a/"
                >
                  Devs Jean
                </a>
              </p>
            </div>
          </div>
          <div className="w-9/12  h-0.5 bg-gray-100 rounded-full" />
          <div className="flex justify-between items-center pt-12"></div>
        </div>
      </div>
    </div>
  );
}
